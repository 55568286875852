/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function Model({angle}) {
    const { nodes, materials } = useGLTF("/ricocode-neonlight.glb");
    const ref = React.useRef()
    const radius = 6.5
    let xPos = null
    let zPos = null
    useFrame(() => {
        xPos = radius * Math.sin(angle)
        zPos = radius * Math.cos(angle)
        angle += 0.001
        ref.current.position.x = xPos 
        ref.current.position.z = zPos 
        ref.current.rotation.z = -angle
    })
    return (
    <group dispose={null}>
        <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        // material={materials["Material.001"]}
        position={[0, 0, 7]}
        rotation={[1.57, 0, 0]}
        ref={ref}
        >
        <meshPhongMaterial
            color="#FF5F5C"
        />
        </mesh>
    </group>
    );
}

useGLTF.preload("/ricocode-neonlight.glb");